<template>
  <v-container fluid class="cii-font">
    <v-row class="cci-content-padding white">
      <v-col md="3" cols="12">
        <v-img
          :src="require('@/assets/img/logo/logo.svg')"
          :max-width="200"
          contain
          class="my-3"
        ></v-img>
        <p style="font-size: 80%">
          Address: SCO 44-45, Choti Baradari Part 2, Jalandhar, Punjab 144022
        </p>

        <v-btn fab depressed class="mr-1" :href="item.link" target="_blank" x-small v-for="(item, index) in socialLinks" :key="index">
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
      </v-col>
      <v-col md="9" cols="12">
        <v-container fluid>
          <v-row>
            <v-col
              md="3"
              sm="4"
              cols="6"
              v-for="(item, index) in FooterLinks"
              :key="index"
            >
              <p style="font-weight: 600" class="mb-1">{{ item.heading }}</p>
              <router-link
                v-for="(itemp, idx) in item.links"
                :key="idx"
                style="display: block; text-decoration: none; color: black"
                :to="itemp.link"
                >{{ itemp.name }}</router-link
              >
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-row style="background-color: #f2f2f2" class="cci-content-padding">
      <v-col md="12" cols="12">
        <p class="mb-0">
          *CII is referred to as Clear Ielts and Immigration on this website
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AppFooter",
  data: () => ({
    socialLinks:[
      {
        name:'LinkedIn',
        icon:'mdi-linkedin',
        link:'https://www.linkedin.com/company/clear-ielts-immigration/'
      },
      {
        name:'Instagram',
        icon:'mdi-instagram',
        link:'https://www.instagram.com/clearieltsimmigration/'
      }
    ],
    FooterLinks: [
      {
        heading: "Quick Links",
        links: [
          { name: "Study Abroad", link: "/study-abroad" },
          { name: "Visa", link: "/visa" },
          { name: "Programs", link: "/programs" },
        ],
      },
      {
        heading: "Resources",
        links: [
          { name: "Privacy & Policy", link: "/privacy-and-policy" },
          { name: "Terms and Conditions", link: "/terms-and-conditions" },
        ],
      },
    ],
  }),
};
</script>

<style>
</style>