<template>
  <v-dialog v-model="dialog" persistent max-width="1024">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        rounded
        depressed
        v-if="country.length && visa_type.length"
        color="#5A199B"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Connect with Us
      </v-btn>

      <v-btn
        rounded
        depressed
        v-else
        disabled
        color="#5A199B"
        v-bind="attrs"
        v-on="on"
      >
        Connect with Us
      </v-btn>
    </template>
    <v-card style="border-radius: 12px; background-color: #eeebe6">
      <v-card-title class="text-h5">
        <!-- Use Google's location service? -->
        <v-spacer></v-spacer>
        <v-btn fab rounded text small @click="dialog = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col md="7">
              <p style="font-size: 150%">
                Almost there!
                <br />
                Please enter following detail <br />
                for visa assistance.
              </p>

              <p class="mb-0"><b>Visa Type:</b> {{ visa_type }}</p>
              <p class="mb-0"><b>Country:</b> {{ country }}</p>

              <v-form ref="form" v-model="valid" lazy-validation>
                <v-container fluid class="pa-0">
                  <v-row class="my-0">
                    <v-col md="6" class="my-0">
                      <v-text-field
                        rounded
                        label="Full Name"
                        background-color="white"
                        :rules="[rules.required]"
                        placeholder="Full Name"
                        class="my-n3"
                        v-model="formData.fullname"
                        height="50"
                        >asd</v-text-field
                      >
                    </v-col>
                  </v-row>
                  <v-row class="my-0">
                    <v-col md="6" class="my-0">
                      <v-text-field
                        rounded
                        label="Mobile"
                        background-color="white"
                        :rules="[rules.required, rules.mobile]"
                        placeholder="Mobile"
                        v-model="formData.mobile"
                        class="my-n3"
                        height="50"
                        prefix="+91-"
                        type="number"
                        >asd</v-text-field
                      >
                    </v-col>
                    <v-col md="6" class="my-0">
                      <v-text-field
                        rounded
                        label="Email"
                        background-color="white"
                        placeholder="Email"
                        v-model="formData.email"
                        class="my-n3"
                        height="50"
                        >asd</v-text-field
                      >
                    </v-col>
                  </v-row>
                  <v-row class="my-0">
                    <v-col md="12" class="my-0">
                      <v-textarea
                        rounded
                        label="Write Here"
                        v-model="formData.query.message"
                        :rules="[rules.required]"
                        rows="5"
                        background-color="white"
                        placeholder="Write Here"
                        class="my-n3"
                      ></v-textarea>
                    </v-col>

                    <v-col md="12">
                      <v-btn
                        dark
                        rounded
                        color="#5A199B"
                        depressed
                        @click="submit"
                        :loading="loading"
                        >Submit</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-col>
            <v-col md="5">
              <v-img
                width="100%"
                :src="require('@/assets/img/visa/visa-contact-card.png')"
              ></v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { rules } from "@/utils/rules";
export default {
  name: "VisaContact",
  props: ["country", "visa_type"],
  data: () => ({
    valid: false,
    rules: rules,
    dialog: false,
    loading: false,
    formData: {
      fullname: "",
      email: "",
      mobile: "",
      type: "",
      query: {
        study: {
          course: "",
          university: "",
          country: "",
          state: "",
        },
        visa: {
          visaType: "",
          country: "",
        },
        message: "",
      },
      status: "Unviewed",
    },
  }),
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          let dataToSend = this.formData;
          dataToSend.type = "VISA";
          let res = await this.$store.dispatch(
            "contactStore/addQuery",
            dataToSend
          );
          if (res.success) {
            this.$store.commit("SET_SNACKBAR", "Thank you for Reaching out");
            this.loading = false;
            this.dialog = false;
            this.$refs.form.reset();
          }
          this.loading = false;
        } catch (error) {
          console.log("error", error);
          this.loading = false;
          this.dialog = false;
        }
      }
    },
  },
};
</script>

<style>
</style>