<template>
  <v-row justify="center" align="center" class="my-5">
    <v-col :md="8">
      <p class="mb-0 cii-subheading">100% Assured</p>
      <p class="cii-heading-1 cii-animation-font">
        Education & <br />
        Visa Assistance
      </p>
      <p>
        Begin your journey to success with
        <span style="color: #5a199b">CLEAR IELTS IMMIGRATION (CII)</span>.
        <br />
        Unlock Global Opportunities: Your Pathway to Studying Abroad with Expert Consulting Services” - From visa assistance to relocation support, we're here to help you script your success story.
      </p>
    </v-col>
    <v-col :md="4" class="text-center">
      <v-img width="100%" :src="require('@/assets/img/home/home.png')"></v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "HomeHero",
};
</script>

<style>
</style>