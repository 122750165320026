<template>
  <v-container fluid>
    <v-row
      class="px-0 mx-0"
      justify="center"
      style="margin-top: 5%; margin-bottom: 5%"
    >
      <v-col md="12" cols="12" class="text-center">
        <p class="mb-0 cii-heading-2">
          Guaranteed 7+ Band Score in IELTS on Your First Attempt! <br> with our Personalized Action Plan.
        </p>
        <p>
          Elevate Your Potential and  Excel in exams such as IELTS, TOEFL, GRE, GMAT, and beyond with our tailored test preparation courses. Led by seasoned professional instructors, our comprehensive programs are enriched with valuable resources to ensure your success.
        </p>
        <!-- <p>Guaranteed '7+ Band Score' in the first attemps with our personalised action plan</p> -->
        <!-- <p>Instant | In a Week | In a month | In a two months</p> -->
      </v-col>
      <v-col
        md="4"
        cols="12"
        sm="4"
        class="mx-0 flex-container"
        v-for="(item, index) in programData.slice(0, 4)"
        :key="index"
      >
        <program-card-vue :item="item" />
      </v-col>

      <v-col class="text-center mb-0" md="12" v-if="!programData.length">
        <v-progress-circular
          :size="50"
          color="indigo"
          indeterminate
        ></v-progress-circular>
      </v-col>

      <v-col md="12" class="text-center">
        <p>
          Designed to help you achieve your best performance in exams. Led by
          experienced instructors and enriched with valuable resources
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProgramCardVue from "../programs/ProgramCard.vue";

export default {
  components: {
    ProgramCardVue,
  },
  data: () => ({
    programData: [],
  }),
  created() {
    this.getAllPrograms();
  },
  methods: {
    async getAllPrograms() {
      try {
        let res = await this.$store.dispatch("programStore/getProgramsByOrder");
        if (res.success) {
          this.programData = res.data;
        }
      } catch (error) {
        console.log("errpr", error);
      }
    },
  },
};
</script>

<style>
</style>