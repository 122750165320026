<template>
  <v-system-bar app color="black" dark text class="cci-content-padding">
    <router-link
      to="/contact"
      class="mx-3"
      style="text-decoration: none; color: white; font-size: 90%"
      >Book Counselling <v-icon>mdi-arrow-right-thin</v-icon>
    </router-link>
    <v-spacer></v-spacer>
    <!-- For Large Screen -->
    <div class="hidden-sm-and-down" style="font-size: 85%; color: white">
      <a
        class="mx-3"
        href="tel:+919041950494"
        target="_blank"
        style="text-decoration: none; color: white"
      >
        <v-icon>mdi-phone</v-icon> +91 9041950494
      </a>
      <a
        class="mx-3"
        href="mailto:info.clearielts@gmail.com"
        target="_blank"
        style="text-decoration: none; color: white"
      >
        <v-icon>mdi-email</v-icon> info.clearielts@gmail.com
      </a>
      <span
        class="mx-3"
        target="_blank"
        style="text-decoration: none; color: white"
        ><v-icon>mdi-map-marker</v-icon> SCO 44-45, Jalandhar, Punjab
        144022</span
      >
    </div>
    <!-- For Large Screen -->

    <!-- For Small Screen -->
    <div class="hidden-sm-and-up px-2" style="font-size: 90%">
      <v-btn
        x-small
        fab
        dark
        text
        class="ml-2"
        href="tel:+919041950494"
        target="_blank"
        ><v-icon small>mdi-phone</v-icon></v-btn
      >
      <v-btn
        x-small
        fab
        dark
        text
        class="ml-2"
        href="mailto:info.clearielts@gmail.com"
        target="_blank"
        ><v-icon small>mdi-email</v-icon></v-btn
      >
      <v-btn x-small fab dark text class="mx-2" target="_blank"
        ><v-icon small>mdi-map-marker</v-icon></v-btn
      >
    </div>
    <!-- For Small Screen -->
  </v-system-bar>
</template>

<script>
export default {
  name: "AppSystemBar",
};
</script>

<style>
</style>