import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomePage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Home'
    }
  },
  {
    path: '/study-abroad',
    name: 'study-abroad',
    component: () => import(/* webpackChunkName: "study-abroad" */ '../views/StudyAbroadPage.vue'),
    meta: {
      title: 'Study Abroad'
    }
  },
  {
    path: '/study-abroad/search',
    name: 'study-abroad-search',
    component: () => import(/* webpackChunkName: "study-abroad-search" */ '../views/StudyAbroadSearchPage.vue'),
    meta: {
      title: 'Study Abroad Search'
    }
  },
  {
    path: '/universities/:id',
    name: 'university-page',
    component: () => import(/* webpackChunkName: "university-page" */ '../views/UniversityInfoPage.vue'),
    meta: {
      title: 'Universities'
    }
  },
  {
    path: '/visa',
    name: 'visa',
    component: () => import(/* webpackChunkName: "visa" */ '../views/VisaPage.vue'),
    meta: {
      title: 'Visa'
    }
  },
  {
    path: '/programs',
    name: 'programs',
    component: () => import(/* webpackChunkName: "programs" */ '../views/ProgramsPage.vue'),
    meta: {
      title: 'Programs'
    }
  },
  {
    path: '/programs/:id',
    name: 'program-info',
    component: () => import(/* webpackChunkName: "program-info" */ '../views/ProgramInfoPage.vue'),
    meta: {
      title: 'Program Info'
    }
  },
  {
    path: '/careers',
    name: 'careers',
    component: () => import(/* webpackChunkName: "careers" */ '../views/CareersPage.vue'),
    meta: {
      title: 'Careers'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactPage.vue'),
    meta: {
      title: 'Contact'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutPage.vue'),
    meta: {
      title: 'About'
    }
  },
  {
    path: '/privacy-and-policy',
    name: 'privacy-and-policy',
    component: () => import(/* webpackChunkName: "privacy-and-policy" */ '../views/PolicyPage.vue'),
    meta: {
      title: 'Privacy and Policy'
    }
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: () => import(/* webpackChunkName: "terms-and-conditions" */ '../views/TermsPage.vue'),
    meta: {
      title: 'Terms and Conditions'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + ' - CLEAR IELTS IMMIGRATION (CII)'
  next()
})

export default router
