<template>
  <v-container fluid>
    <v-row justify="center">
        <v-col cols="12" md="10" class="text-center">
            <p class="cii-heading-2">Our Success Stories</p>
        </v-col>
    </v-row>
    <v-row justify="center">
        <v-col md="2" cols="6" sm="3">
            <p class="cii-animation-font cii-heading-state mb-0">25+</p>
            <p style="font-size: 110%;">Countries</p>
        </v-col>
        <v-col md="2" cols="6" sm="3">
            <p class="cii-animation-font cii-heading-state mb-0">1000+</p>
            <p style="font-size: 110%;">Visa Issued</p>
        </v-col>
        <v-col md="2" cols="6" sm="3">
            <p class="cii-animation-font cii-heading-state mb-0">500+</p>
            <p style="font-size: 110%;">Universities</p>
        </v-col>
        <v-col md="2" cols="6" sm="3">
            <p class="cii-animation-font cii-heading-state mb-0">1200+</p>
            <p style="font-size: 110%;">Students Trained</p>
        </v-col>
    </v-row>

    <v-row class="px-md-15 my-10">
        <v-col md="2" cols="4" sm="3" v-for="i in 12" :key="i">
            <v-img :src="require('@/assets/img/common/story/'+i+'.svg')"></v-img>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>