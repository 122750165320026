<template>
  <v-container fluid class="ma-0 py-6 pa-0">
    <v-row class="ma-0 pa-0" justify="center" align="center">
      <v-col md="7" cols="12" class="ma-0 pa-0">
        <v-container fluid class="ma-0 pa-0">
          <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col :md="12" cols="12">
              <p class="mb-0 cii-title">
                Get expert guidance and support <br />
                for your visa application and immigration process.
              </p></v-col
            >
            <v-col :md="5" cols="12">
              <v-select
                background-color="white"
                rounded
                class="my-n3"
                placeholder="Select Visa Type"
                :items="['Study', 'Business', 'Tourist']"
                v-model="formData.visa_type"
                height="50"
              ></v-select>
            </v-col>
            <v-col :md="5" cols="12">
              <v-select
                background-color="white"
                rounded
                class="my-n3"
                placeholder="Select Country"
                v-model="formData.country"
                :items="countriesOptions"
                height="50"
              ></v-select>
            </v-col>

            <v-col :md="12" cols="12">
              <VisaContactVue
                :country="formData.country"
                :visa_type="formData.visa_type"
              />
              <p class="mb-0 mt-8" style="font-size: 80%">
                <v-icon>mdi-information-outline</v-icon> Didn't find your
                country listed? Reach out us
              </p>
            </v-col>
          </v-row>
        </v-form>
        </v-container>
      </v-col>
      <v-col md="5" cols="12" class="text-center">
        <v-img
          :src="require('@/assets/img/finder/visa.png')"
          width="100%"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import VisaContactVue from "@/components/visa/VisaContact.vue";
import { rules } from "@/utils/rules";
export default {
  data: () => ({
    valid: false,
    rules: rules,
    countriesOptions: [
      "Afghanistan",
      "Albania",
      "Algeria",
      "Andorra",
      "Angola",
      "Antigua and Barbuda",
      "Argentina",
      "Armenia",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Bahamas",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bhutan",
      "Bolivia",
      "Bosnia and Herzegovina",
      "Botswana",
      "Brazil",
      "Brunei",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Cabo Verde",
      "Cambodia",
      "Cameroon",
      "Canada",
      "Central African Republic",
      "Chad",
      "Chile",
      "China",
      "Colombia",
      "Comoros",
      "Congo",
      "Costa Rica",
      "Croatia",
      "Cuba",
      "Cyprus",
      "Czech Republic",
      "Democratic Republic of the Congo",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic",
      "East Timor",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Eritrea",
      "Estonia",
      "Eswatini",
      "Ethiopia",
      "Fiji",
      "Finland",
      "France",
      "Gabon",
      "Gambia",
      "Georgia",
      "Germany",
      "Ghana",
      "Greece",
      "Grenada",
      "Guatemala",
      "Guinea",
      "Guinea-Bissau",
      "Guyana",
      "Haiti",
      "Honduras",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Iran",
      "Iraq",
      "Ireland",
      "Israel",
      "Italy",
      "Ivory Coast",
      "Jamaica",
      "Japan",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Kiribati",
      "Kosovo",
      "Kuwait",
      "Kyrgyzstan",
      "Laos",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Marshall Islands",
      "Mauritania",
      "Mauritius",
      "Mexico",
      "Micronesia",
      "Moldova",
      "Monaco",
      "Mongolia",
      "Montenegro",
      "Morocco",
      "Mozambique",
      "Myanmar",
      "Namibia",
      "Nauru",
      "Nepal",
      "Netherlands",
      "New Zealand",
      "Nicaragua",
      "Niger",
      "Nigeria",
      "North Korea",
      "North Macedonia",
      "Norway",
      "Oman",
      "Pakistan",
      "Palau",
      "Palestine",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines",
      "Poland",
      "Portugal",
      "Qatar",
      "Romania",
      "Russia",
      "Rwanda",
      "Saint Kitts and Nevis",
      "Saint Lucia",
      "Saint Vincent and the Grenadines",
      "Samoa",
      "San Marino",
      "Sao Tome and Principe",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Slovakia",
      "Slovenia",
      "Solomon Islands",
      "Somalia",
      "South Africa",
      "South Korea",
      "South Sudan",
      "Spain",
      "Sri Lanka",
      "Sudan",
      "Suriname",
      "Sweden",
      "Switzerland",
      "Syria",
      "Taiwan",
      "Tajikistan",
      "Tanzania",
      "Thailand",
      "Togo",
      "Tonga",
      "Trinidad and Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Tuvalu",
      "Uganda",
      "Ukraine",
      "United Arab Emirates",
      "United Kingdom",
      "United States",
      "Uruguay",
      "Uzbekistan",
      "Vanuatu",
      "Vatican City",
      "Venezuela",
      "Vietnam",
      "Yemen",
      "Zambia",
      "Zimbabwe",
    ],
    formData: {
      country: "",
      visa_type: "",
    },
  }),
  components: {
    VisaContactVue,
  },
};
</script>
  
  <style>
</style>