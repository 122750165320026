<template>
  <v-navigation-drawer
    clipped
    color="white"
    floating
    v-model="drawer"
    app
    temporary
    width="300px"
  >
    <v-list-item>
      <v-img
        :src="require('@/assets/img/logo/logo.svg')"
        :max-width="150"
        covered
      ></v-img>
      <!-- <v-list-item-content>
        <v-list-item-title
          class="tf-font py-1"
          style="font-size: 130%; font-weight: 400"
        >
          CII
        </v-list-item-title>
      </v-list-item-content> -->
    </v-list-item>
    <v-divider></v-divider>

    <v-list dense shaped>
      <!-- Home -->
      <v-list-item :to="item.to" dense color="indigo" v-for="(item, index) in links" :key="index">
        <!-- <v-list-item-icon>
          <v-icon>mdi-home-outline</v-icon>
        </v-list-item-icon> -->
        <v-list-item-title>{{item.name}}</v-list-item-title>
      </v-list-item>
      <!-- Home -->

    </v-list>
    <!-- Management -->
    <!-- Brand Requirement -->
  </v-navigation-drawer>
</template>
      
      <script>
export default {
  name: "AppDrawer",
  data: () => ({
    links: [
      { name: "Home", to: "/" },
      { name: "Study Abroad", to: "/study-abroad" },
      { name: "Visa", to: "/visa" },
      { name: "Programs", to: "/programs" },
      { name: "About Us", to: "/about" },
      { name: "Careers", to: "/careers" },
      { name: "Contact", to: "/contact" },
    ],
  }),
  mounted() {},
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
  },
};
</script>
      