<template>
  <v-container
    fluid
    style="border-radius: 20px"
    class="pa-md-8 pa-4 cii-font"
    :style="{ backgroundColor: bgColor }"
  >
    <v-row justify="center" align="center">
      <v-col md="4" sm="7" cols="7" class="text-right">
        <p class="mb-0 cii-subheading" style="color: #414141;font-size: 250%;font-weight: 500;">
          Explore In-Person Learning Excellence at CII Coaching Center
        </p>
        <!-- <p
          class="mb-0 mt-0 offline-class-heading"
          style="color: #414141;"
        >
          Coaching <br />Center
        </p> -->
      </v-col>
      <v-col md="3" sm="5" cols="5" class="text-center">
        <v-img :src="require('@/assets/img/class/class.png')"></v-img>
      </v-col>
      <v-col md="4" sm="12" class="">
        <v-icon size="40">mdi-map-marker-account-outline</v-icon>
        <p>
          <b>Coaching center in Jalandhar, Punjab</b> <br />
          SCO 44-45, ITI College Rd, opp. PIMS hospital Choti Baradari Part 2,
          Jalandhar, Punjab 144022
        </p>
        <v-img
          :src="require('@/assets/img/class/courses.svg')"
          width="70%"
          class="mb-4"
        ></v-img>
        <v-btn depressed color="#5A199B" dark rounded to="/programs"
          >Enroll for Offline Study</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["bgColor"],
  name: "OfflineClass",
};
</script>

<style>
</style>