<template>
  <v-container fluid>
    <v-row
      justify="center"
      align="center"
      style="border-radius: 20px"
      class="pa-md-8 pa-2"
    >
      <v-col :md="12" cols="12">
        <p class="mb-2">Chouse your path</p>

        <v-btn
          color="#5A199B"
          :outlined="btnType == 1 ? false : true"
          @click="btnType = 1"
          rounded
          depressed
          dark
          small
          class="mr-2 mb-2"
        >
          <v-icon left>mdi-school-outline</v-icon>
          Course finder</v-btn
        >
        <v-btn
          color="#5A199B"
          @click="btnType = 2"
          :outlined="btnType == 2 ? false : true"
          rounded
          depressed
          dark
          small
          class="mr-2 mb-2"
        >
          <v-icon left>mdi-airplane</v-icon>
          Visa Info</v-btn
        >
        <v-btn
          color="#5A199B"
          @click="btnType = 3"
          :outlined="btnType == 3 ? false : true"
          rounded
          depressed
          dark
          small
          class="mb-2"
        >
          <v-icon left>mdi-account-tie-voice</v-icon>
          Counseling</v-btn
        >

        <!-- <v-fade-transition> -->
          <course-finder-vue class="" v-if="btnType == 1" />
          <visa-finder-vue v-if="btnType == 2" />
          <counseling-founder-vue v-if="btnType == 3" />
        <!-- </v-fade-transition> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CounselingFounderVue from "./Finder/CounselingFinder.vue";
import CourseFinderVue from "./Finder/CourseFinder.vue";
import VisaFinderVue from "./Finder/VisaFinder.vue";

export default {
  name: "FinderComponentC",
  data: () => ({
    btnType: 1,
  }),
  components: {
    CourseFinderVue,
    VisaFinderVue,
    CounselingFounderVue,
  },
};
</script>

<style>
</style>