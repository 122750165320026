import Vue from 'vue'
import Vuex from 'vuex'

import programStore from './modules/program.store'
import universityStore from './modules/university.store'
import contactStore from './modules/contact.store'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: false,
    snackbar: {
      isSnackbarVisible: false,
      snackbarText: ''
    }
  },
  getters: {
    GET_SNACKBAR: (state) => state.snackbar
  },
  mutations: {
    SET_DRAWER: (state, payload) => (state.drawer = payload),
    TOGGLE_DRAWER: (state) => (state.drawer = !state.drawer),
    SET_SNACKBAR: (state, payload) => (state.snackbar.isSnackbarVisible = true, state.snackbar.snackbarText = payload),
    TOGGLE_SNACKBAR: (state) => (state.snackbar.isSnackbarVisible = !state.snackbar.isSnackbarVisible)
  },
  actions: {
  },
  modules: {
    universityStore,
    programStore,
    contactStore
  }
})
