import { db } from "@/config/firebase";
import {
    collection,
    getDocs,
    doc,
    getDoc,
    orderBy,
    query
    // setDoc
} from "firebase/firestore"

const collectionName = 'programs'

const state = {
    allPrograms: []
}

const getters = {

}

const mutations = {
    doNothing: (state) => state
}

const actions = {

    /**
     * @description Get All Programs
     * @returns {Object} Object
    */
    async getPrograms({ commit }) {
        let programs = []
        return new Promise((resolve, reject) => {
            getDocs(collection(db, collectionName)).then((querySnapshot => {
                querySnapshot.forEach(doc => {
                    programs.push({ ...doc.data(), ...{ docid: doc.id } })
                })
                commit('doNothing')
                resolve({
                    success: true,
                    data: programs
                })
            })).catch(err => {
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },

    /**
     * @description Get All Programs By Order
     * @returns {Object} Object
    */
    async getProgramsByOrder({ commit }) {
        let programs = []
        let orderQuery = query(collection(db, collectionName), orderBy('createdAt', 'desc'));

        return new Promise((resolve, reject) => {
            getDocs(orderQuery).then((querySnapshot => {
                querySnapshot.forEach(doc => {
                    programs.push({ ...doc.data(), ...{ docid: doc.id } })
                })
                commit('doNothing')
                resolve({
                    success: true,
                    data: programs
                })
            })).catch(err => {
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },

    /**
     * @description Get Program info by Id
     * @param {Number} - Doc Id
     * @returns {Object} Object
    */
    async getProgramById({ commit }, docid) {
        return new Promise((resolve, reject) => {
            let docRef = doc(db, collectionName, docid)
            getDoc(docRef).then(docSnap => {
                if (docSnap.exists()) {
                    commit('doNothing')
                    resolve({
                        success: true,
                        data: docSnap.data()
                    })
                } else {
                    resolve({
                        success: false,
                        message: 'No Data Found'
                    })
                }
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};