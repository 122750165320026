<template>
  <v-container fluid class="ma-0 py-6 pa-0">
    <v-row class="ma-0 pa-0" justify="center" align="center">
      <v-col md="7" sm="12" cols="12" class="ma-0 pa-0">
        <v-container fluid class="ma-0 pa-0">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col md="12" cols="12">
                <p class="mb-0 cii-title">
                  Here's Everything <br>
                  you need to start your application with us.
                </p>
              </v-col>
              <v-col :md="5" cols="12">
                <v-select
                  background-color="white"
                  rounded
                  v-model="formData.country"
                  class="my-n3"
                  placeholder="Select your Country"
                  :items="countriesOptions"
                  item-text="country"
                  item-value="country"
                  :rules="[rules.required]"
                  height="50"
                  :loading="!countriesOptions.length"
                ></v-select>
              </v-col>
              <v-col :md="5" cols="12">
                <v-select
                  background-color="white"
                  rounded
                  :disabled="!formData.country.length"
                  v-model="formData.state"
                  class="my-n3"
                  :rules="[rules.required]"
                  placeholder="Select Province"
                  :items="statesOptions"
                  height="50"
                ></v-select>
              </v-col>
              <v-col :md="12" cols="12">
                <v-btn
                  depressed
                  color="#5A199B"
                  rounded
                  dark
                  class="mr-2"
                  @click="searchData"
                >
                  <v-icon>mdi-search-web</v-icon>
                  Search Course</v-btn
                >
                <p class="mb-0 mt-8" style="font-size: 80%">
                  <v-icon>mdi-information-outline</v-icon> Didn't Find your
                  course? Reach out us
                </p>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-col>
      <v-col md="5" sm="12" cols="12" class="text-center">
        <v-img
          :src="require('@/assets/img/finder/course.png')"
          width="100%"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { rules } from "@/utils/rules";
export default {
  name: "CourseFinder",
  data: () => ({
    valid: false,
    rules: rules,
    formData: {
      country: "",
      state: "",
    },
    universities: [],
    countriesOptions: [],
  }),
  computed: {
    statesOptions() {
      if (this.formData.country.length) {
        return this.countriesOptions.filter(
          (c) => c.country == this.formData.country
        )[0].states;
      }
      return [];
    },
  },
  created() {
    this.getAllUniversities();
  },
  methods: {
    searchData() {
      if (this.$refs.form.validate()) {
        this.$router.push({
          path: "/study-abroad/search",
          query: {
            country: this.formData.country,
            state: this.formData.state,
          },
        });
      }
    },
    async getAllUniversities() {
      try {
        let res = await this.$store.dispatch(
          "universityStore/getUniversitiesByOrder"
        );
        if (res.success) {
          this.universities = res.data;
          this.getAllCountries();
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    getAllCountries() {
      // Object to hold country-wise states
      const countryStates = {};
      // Iterate over the data and group states by country
      this.universities.forEach((entry) => {
        const country = entry.location.country;
        const state = entry.location.state;

        // Check if the country exists in the object, if not, create an empty array
        if (!countryStates[country]) {
          countryStates[country] = [];
        }

        // Append the state to the array of states for the corresponding country
        if (!countryStates[country].includes(state)) {
          countryStates[country].push(state);
        }
      });

      // Convert the object to the desired format
      const result = Object.entries(countryStates).map(([country, states]) => ({
        country,
        states,
      }));
      this.countriesOptions = result;
    },
  },
};
</script>

<style>
</style>