<template>
  <v-app-bar color="white" app fixed flat class="cci-content-padding">
    <v-toolbar-title class="px-0">
      <router-link to="/">
        <v-img
          :src="require('@/assets/img/logo/logo.svg')"
          :max-width="150"
          covered
        ></v-img>
      </router-link>
    </v-toolbar-title>

    <v-spacer></v-spacer>
    <v-app-bar-nav-icon
      class="d-md-none d-lg-none"
      @click="toggleDrawer"
    ></v-app-bar-nav-icon>
    <v-btn
      class="ml-2 hidden-sm-and-down cii-font"
      style="text-transform: capitalize"
      text
      rounded
      small
      v-for="(item, index) in links"
      :key="index"
      :to="item.to"
      >{{ item.name }}</v-btn
    >
  </v-app-bar>
</template>

<script>
export default {
  data: () => ({
    links: [
      // { name: "Home", to: "/" },
      { name: "Study Abroad", to: "/study-abroad" },
      { name: "Visa", to: "/visa" },
      { name: "Programs", to: "/programs" },
      { name: "About Us", to: "/about" },
      { name: "Careers", to: "/careers" },
      { name: "Contact", to: "/contact" },
    ],
  }),
  methods: {
    toggleDrawer() {
      this.$store.commit("TOGGLE_DRAWER");
    },
  },
};
</script>

<style>
</style>