<template>
  <v-app style="background-color: #F3F3F3">
    <AppSnackbarVue/>
    <AppSystemBarVue />
    <AppToolbarVue />
    <AppDrawerVue/>
    <v-main class="ma-0">
        <v-slide-y-reverse-transition>
          <router-view v-show="show" />
        </v-slide-y-reverse-transition>
    </v-main>
    <AppFooterVue />
  </v-app>
</template>

<script>
import AppDrawerVue from './components/core/AppDrawer.vue';
import AppFooterVue from "./components/core/AppFooter.vue";
import AppSnackbarVue from './components/core/AppSnackbar.vue';
import AppSystemBarVue from "./components/core/AppSystemBar.vue";
import AppToolbarVue from "./components/core/AppToolbar.vue";


export default {
  name: "App",
  data: () => ({
    show: false,
  }),
  components: {
    AppSystemBarVue,
    AppToolbarVue,
    AppFooterVue,
    AppDrawerVue,
    AppSnackbarVue
  },
  created() {
    this.show = true;
  },
};
</script>
