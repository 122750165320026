<template>
  <v-main class="pa-0 ma-0">
    <v-container
      fluid
      class="cii-font cci-content-padding white"
      style="
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;
        z-index: 1;
        position: relative;
      "
    >
      <HomeHeroVue />
    </v-container>

    <v-container
      fluid
      class="cii-font cci-content-padding mt-n10"
      style="background-color: #eeebe6; z-index: -1"
    >
      <v-row justify="center" align="center" class="py-10">
        <FinderVue />
      </v-row>
    </v-container>

    <v-container
      fluid
      class="cii-font cci-content-padding white mt-n10"
      style="border-top-left-radius: 50px; border-top-right-radius: 50px"
    >
      <HomeImpectVue class="my-10" />
      <HomeProgramsVue />
    </v-container>

    <v-container
      fluid
      class="cii-font cci-content-padding"
      style="background-color: #e1e6ea"
    >
      <v-row class="my-10 mb-16">
        <OfflineClassVue bgColor="#e1e6ea" />
      </v-row>
    </v-container>
 
    <v-container
      fluid
      class="cii-font cci-content-padding white mt-n10"
      style="border-radius: 50px"
    >
      <HomeWorkFlowVue class="my-15" />
    </v-container>
   
    <v-container
      fluid
      class="cii-font cci-content-padding"
      style="background-color: #e1e6ea"
    >
      <v-row class="my-10 mb-16">
        <HomeStoryVue />
        <HomeTestimonialVue />
      </v-row>
    </v-container> 
  </v-main>
</template>

<script>
import HomeHeroVue from "@/components/home/HomeHero.vue";
import FinderVue from "@/components/common/Finder.vue";
import HomeImpectVue from "@/components/home/HomeImpect.vue";
import HomeProgramsVue from "@/components/home/HomePrograms.vue";
import OfflineClassVue from "@/components/common/OfflineClass.vue";
import HomeWorkFlowVue from "@/components/home/HomeWorkFlow.vue";
import HomeStoryVue from "@/components/home/HomeStory.vue";
import HomeTestimonialVue from "@/components/home/HomeTestimonial.vue";


export default {
  name: "HomePage",
  components: {
    HomeHeroVue,
    FinderVue,
    HomeProgramsVue,
    HomeImpectVue,
    HomeStoryVue,
    OfflineClassVue,
    HomeWorkFlowVue,
    HomeTestimonialVue
  },
  data: () => ({}),
};
</script>
