<template>
  <v-card
    class="pa-md-10 pa-7 flex-item"
    style="border-radius: 38px"
    :style="{
      backgroundColor: getColor(item.type),
    }"
    elevation="0"
  >
    <v-img
      width="60"
      class="mb-3"
      :src="require('@/assets/img/courses/' + getLogo(item.type))"
    ></v-img>
    <p class="mb-2 cii-title" v-html="item.name"></p>
    <p>{{ item.des }}</p>
    <p v-for="(point, index) in item.takeaways" :key="index" class="mb-0">
      <v-icon color="#7AC500">mdi-checkbox-marked</v-icon> {{ point }}
    </p>
    <v-btn
      class="mt-5"
      color="#5A199B"
      dark
      rounded
      depressed
      :to="'/programs/' + item.docid"
      >Enroll for INR {{ item.fees }}/-</v-btn
    >
  </v-card>
</template>

<script>
export default {
  name: "ProgramCard",
  props: ["item"],
  methods: {
    getLogo(type) {
      if (type == "GRE") {
        return "gre.svg";
      }
      if (type == "TOFEL") {
        return "tofel.svg";
      }
      if (type == "IELTS") {
        return "ielts.svg";
      }
    },
    getColor(type) {
      if (type == "GRE") {
        return "#EFF8F8";
      }
      if (type == "TOFEL") {
        return "#F6EEFA";
      }
      if (type == "IELTS") {
        return "#FAEEF1";
      }
    },
  },
};
</script>

<style>
</style>