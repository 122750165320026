export const rules = {
    required: (value) => !!value || "Required.",

    // Logic for Name Validation
    full_name: (value) => {
        const name = /^[a-zA-Z-'. ]+$/;
        return name.test(value) || "Input value contains other syantax.";
    },
    // Logic for Email Validation
    email: (value) => {
        if (value?.length) {
            const pattern =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
        }
        return "";
    },

    // Logic for Mobile Validation
    mobile: (value) => {
        const pattern1 = /^[4-9]{1}[)]?[-\s/.]?[0-9]{5}[-\s/.]?[0-9]{4}$/im;
        return pattern1.test(value) || "Invalid Mobile Number";
    },

    pincode: (value) => {
        if (!value) return "This field is required.";
        const pincodes = value.toString().split(",").map((pin) => pin.trim());
        const hasDuplicates = new Set(pincodes).size !== pincodes.length;
        if (hasDuplicates) return "Duplicate pincodes are not allowed.";

        const invalidPincodes = pincodes.filter((pin) => {
            const isInvalidLength = pin.length !== 6;
            const isNotAllDigits = !/^\d+$/.test(pin);
            return isInvalidLength || isNotAllDigits;
        });
        if (invalidPincodes.length) {
            return "Each pincode should be exactly 6 digits long and contain only digits.";
        }

        return true;
    },

    // Logic for Google Map URL Validation
    mapUrlValidation(value) {
        const pattern1 =
            /^https:\/\/www\.google\.com\/maps\/place\/(?:.*)\/@([-0-9.]+),([-0-9.]+),(\d+)z\/data=/;
        const pattern2 = /^https:\/\/goo\.gl\/maps\/(?:.*)/;
        const pattern3 = /^https:\/\/maps\.app\.goo\.gl\/(?:.*)/;
        if (
            !pattern1.test(value) &&
            !pattern2.test(value) &&
            !pattern3.test(value)
        ) {
            return "Please enter a valid Google Map URL.";
        }
        return true;
    },
};
