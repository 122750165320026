import { collectionName } from "@/config/collections";
import { db } from "@/config/firebase";
import {
    collection,
    getDocs,
    doc,
    getDoc,
    orderBy,
    query,
    where
    // setDoc
} from "firebase/firestore"


// const collectionName = 'universities'

const state = {
    allUniversities: []
}

const getters = {

}

const mutations = {
    doNothing: (state) => state
}

const actions = {
    /**
     * @description Get All Universities
     * @returns {Object} Object
    */
    async getUniversities({ commit }) {
        let universities = []
        return new Promise((resolve, reject) => {
            getDocs(collection(db, collectionName.universities)).then((querySnapshot => {
                querySnapshot.forEach(doc => {
                    universities.push({ ...doc.data(), ...{ docid: doc.id } })
                })
                commit('doNothing')
                resolve({
                    success: true,
                    data: universities
                })
            })).catch(err => {
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },

    /**
     * @description Get All Universities By Order
     * @returns {Object} Object
    */
    async getUniversitiesByOrder({ commit }) {
        // console.log('asdasd asd s');
        let universities = []
        let orderQuery = query(collection(db, collectionName.universities), orderBy('createdAt', 'desc'));

        return new Promise((resolve, reject) => {
            getDocs(orderQuery).then((querySnapshot => {
                querySnapshot.forEach(doc => {
                    universities.push({ ...doc.data(), ...{ docid: doc.id } })
                })
                commit('doNothing')
                // console.log('asd', universities);
                resolve({
                    success: true,
                    data: universities
                })
            })).catch(err => {
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },

    /**
     * @description Get University info by Id
     * @param {Number} - Doc Id
     * @returns {Object} Object
    */
    async getUniversityById({ commit }, docid) {
        return new Promise((resolve, reject) => {
            let docRef = doc(db, collectionName.universities, docid)
            getDoc(docRef).then(docSnap => {
                if (docSnap.exists()) {
                    commit('doNothing')
                    resolve({
                        success: true,
                        data: docSnap.data()
                    })
                } else {
                    resolve({
                        success: false,
                        message: 'No Data Found'
                    })
                }
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },

    /**
     * @description Search University info by Country and state
     * @param {Number} - Doc Id
     * @returns {Object} Object
    */
    async searchUniversityByCountryAndState({ commit }, payload) {
        return new Promise((resolve, reject) => {
            let universities =[]
            // Construct a query with two where clauses
            const q = query(collection(db, collectionName.universities), 
                    where('location.country', '==', payload.country), 
                    where('location.state', '==', payload.state));

            // Execute the query and get the documents
            getDocs(q).then(querySnapshot=>{
                querySnapshot.forEach(doc=>{
                    universities.push({ ...doc.data(), ...{ docid: doc.id } })
                })
                commit('doNothing')
                resolve({
                    success: true,
                    data: universities
                })
            }).catch(err=>{
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },

    /**
     * @description Get All Courses In University
     * @param {Number} - Doc Id
     * @returns {Object} Object
    */
    async getAllCoursesInUniversity({ commit }, docid) {
        return new Promise((resolve, reject) => {
            let courses = []
            // Reference to the course document within the 'courses' subcollection
            const courseRef = query(collection(db, `${collectionName.universities}/${docid}/${collectionName.courses}`));

            getDocs(courseRef).then((documentSnapshot) => {
                documentSnapshot.forEach(docSnap=>{
                    courses.push({...docSnap.data(), ...{docid: docSnap.id}})
                })
                commit('doNothing')
                resolve({
                    success: true,
                    data: courses
                })

            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })

        })
    },
    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};