<template>
  <v-container fluid>
    <v-row>
      <v-col md="12" class="text-center">
        <p style="" class="mb-0">Our Impact</p>
        <p class="cii-heading-2">Empowering the future of our students</p>

        <v-container fluid>
          <v-row justify="center">
            <v-col md="2" cols="6" sm="3">
              <p class="cii-animation-font cii-heading-state mb-0">25+</p>
              <p style="font-size: 110%">Countries</p>
            </v-col>
            <v-col md="2" cols="6" sm="3">
              <p class="cii-animation-font cii-heading-state mb-0">1000+</p>
              <p style="font-size: 110%">Visa Issued</p>
            </v-col>
            <v-col md="2" cols="6" sm="3">
              <p class="cii-animation-font cii-heading-state mb-0">500+</p>
              <p style="font-size: 110%">Universities</p>
            </v-col>
            <v-col md="2" cols="6" sm="3">
              <p class="cii-animation-font cii-heading-state mb-0">1200+</p>
              <p style="font-size: 110%">Students Trained</p>
            </v-col>
          </v-row>
        </v-container>

        <v-img
          width="90%"
          style="margin: auto"
          :src="require('@/assets/img/home/impact.png')"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>