<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="10" class="text-center">
        <p class="mb-0">Testimonials</p>
        <p class="cii-heading-2">Hear from our students</p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        md="6"
        lg="5"
        cols="12"
        sm="6"
        v-for="(item, index) in testimonialData"
        :key="index"
        class="flex-container"
      >
        <v-card elevation="0" style="border-radius: 30px" class="pa-4">
          <v-container fluid>
            <v-row>
              <v-col md="4" cols="4">
                <v-img :src="require('@/assets/img/common/testi/'+item.image)"></v-img>
              </v-col>
              <v-col md="8" cols="8">
                <p style="font-size: 140%;font-weight: 500;">{{ item.name }}</p>
                <p style="font-size: 85%">{{ item.content }}</p>

                <v-rating
                  v-model="item.star"
                  color="yellow darken-3"
                  background-color="grey darken-1"
                  empty-icon="$ratingFull"
                  readonly
                  size="20"
                  dense
                  style="display: inline;"
                  class="mr-3"
                ></v-rating>
                <span style="font-size: 70%;">{{ item.name }} has rated {{ item.star }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import testimonialJSON from "@/assets/data/testimonial.json";
export default {
  data: () => ({
    testimonialData: testimonialJSON,
  }),
};
</script>
  
  <style>
</style>