import { collectionName } from "@/config/collections";
import { db } from "@/config/firebase";
import {
    collection,
    addDoc,
    serverTimestamp,
    // setDoc
} from "firebase/firestore"

const state = {
    allCourses: []
}

const getters = {

}

const mutations = {
    doNothing: (state) => state
}

const actions = {
    /**
     * @description Add Query 
     * @param {Object} data 
     * @returns {Object} Object
    */
    async addQuery({ commit }, data) {
        data.createdAt = serverTimestamp()
        return new Promise((resolve, reject) => {
            addDoc(collection(db, collectionName.queries), data).then(() => {
                commit('doNothing')
                resolve({
                    success: true,
                    message: 'Message Added Successfully'
                })
            })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};