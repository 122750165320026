<template>
  <v-container fluid class="ma-0 py-6 px-0">
    <v-row class="ma-0 pa-0" justify="center" align="center">
      <v-col md="7" cols="12" sm="12" class="ma-0 pa-0">
        <v-container fluid class="ma-0 pa-0">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col md="12" cols="12">
                <p class="mb-0 cii-title">
                  Need Help? <br />
                  Reach out to our experts for counselling for exams, resources
                  & more
                </p></v-col
              >
              <v-col :md="5" cols="12">
                <v-text-field
                  background-color="white"
                  rounded
                  class="my-n3"
                  placeholder="Full Name"
                  :rules="[rules.required]"
                  v-model="formData.fullname"
                  height="50"
                ></v-text-field>
              </v-col>
              <v-col :md="5" cols="12">
                <v-text-field
                  background-color="white"
                  rounded
                  class="my-n3"
                  placeholder="Email"
                  v-model="formData.email"
                  height="50"
                ></v-text-field>
              </v-col>
              <v-col :md="5" cols="12">
                <v-text-field
                  background-color="white"
                  rounded
                  class="my-n3"
                  placeholder="Mobile"
                  :rules="[rules.required, rules.mobile]"
                  v-model="formData.mobile"
                  height="50"
                ></v-text-field>
              </v-col>

              <v-col :md="10" cols="12">
                <v-textarea
                  background-color="white"
                  rounded
                  class="my-n3"
                  placeholder="Message"
                  :rules="[rules.required]"
                  v-model="formData.query.message"
                  rows="3"
                ></v-textarea>
              </v-col>

              <v-col :md="12" cols="12">
                <v-btn
                  depressed
                  color="#5A199B"
                  rounded
                  dark
                  class="mr-2"
                  @click="submit"
                  :loading="loading"
                >
                  Submit</v-btn
                >
                <p class="mb-0 mt-8" style="font-size: 80%">
                  <v-icon>mdi-information-outline</v-icon> Your email address is
                  required to reach back to you. Privacy Policy
                </p>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-col>
      <v-col md="5" cols="12" sm="12" class="text-center">
        <v-img
          :src="require('@/assets/img/finder/counsil.png')"
          width="100%"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import { rules } from "@/utils/rules";
export default {
  name: "CounselingFinder",
  data: () => ({
    valid: false,
    rules: rules,
    loading: false,
    formData: {
      fullname: "",
      email: "",
      mobile: "",
      type: "",
      query: {
        study: {
          course: "",
          university: "",
          country: "",
          state: "",
        },
        visa: {
          visaType: "",
          country: "",
        },
        message: "",
      },
      status: "Unviewed",
    },
  }),
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          let dataToSend = this.formData;
          dataToSend.type = "GENERAL";

          let res = await this.$store.dispatch(
            "contactStore/addQuery",
            dataToSend
          );
          if (res.success) {
            this.$store.commit("SET_SNACKBAR", "Thank you for Reaching out");
            this.loading = false;
            this.$refs.form.reset();
          }
          this.loading = false;
        } catch (error) {
          console.log("error", error);
          this.loading = false;
        }
      }
    },
  },
};
</script>
  
  <style>
</style>