// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDaq7ifEsY2AIzSUqrVLI_sQWvz2wxmlR8",
  authDomain: "clearielts-prod.firebaseapp.com",
  projectId: "clearielts-prod",
  storageBucket: "clearielts-prod.appspot.com",
  messagingSenderId: "263003821283",
  appId: "1:263003821283:web:f4847bd32628983fd2e1d6",
  measurementId: "G-YNHDPFHCH1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)

export {db}