<template>
  <v-container fluid class="">
    <v-row class="mx-0 px-0 mb-10" style="" justify="center" align="center">
      <v-col md="11" class="text-center">
        <!-- <p class="cii-heading-2">
          Our step-by-step guide helping you to start your <br />
          international journey with confidence.
        </p> -->
        <p class="cii-heading-2">
          Begin Your Path from IELTS Coaching to Visa Succes
        </p>
        <v-btn
          color="#5A199B"
          :outlined="btnType == 1 ? false : true"
          @click="btnType = 1"
          rounded
          depressed
          dark
          small
          class="mr-2 mb-2"
        >
          <v-icon left>mdi-school-outline</v-icon>
          IELTS Preparation</v-btn
        >

        <v-btn
          color="#5A199B"
          :outlined="btnType == 2 ? false : true"
          @click="btnType = 2"
          rounded
          depressed
          dark
          small
          class="mr-2 mb-2"
        >
          <v-icon left>mdi-airplane</v-icon>
          Study Visa Process</v-btn
        >
      </v-col>
    </v-row>
    <!-- Row 5 -->
    <!-- Study -->
    <v-row v-if="btnType == 1" class="px-0" justify="center">
      <v-col
        cols="12"
        md="3"
        lg="2"
        sm="4"
        v-for="(item, index) in StudyWorkflowData"
        :key="index"
        class="flex-container"
      >
        <v-card
          style="background-color: #eeebe6; border-radius: 12px"
          class="pa-4 flex-item"
          elevation="0"
        >
          <v-img
            :src="
              require('@/assets/img/common/steper/studyworkflow/' +
                (index + 1) +
                '.svg')
            "
            width="50"
            class="my-5"
          ></v-img>
          <p class="" style="font-size: 120%; font-weight: 500">
            {{ item.name }}
          </p>
          <p class="mb-0" style="font-size: 95%">{{ item.desc }}</p>
        </v-card>
      </v-col>
    </v-row>
    <!-- Study -->

    <!-- Visa -->
    <v-row v-if="btnType == 2" justify="center">
      <v-col
        cols="12"
        md="3"
        lg="2"
        sm="4"
        v-for="(item, index) in VisaflowData"
        :key="index"
        class="flex-container"
      >
        <v-card
          style="background-color: #eeebe6; border-radius: 12px"
          class="pa-4 flex-item"
          elevation="0"
        >
          <v-img
            :src="
              require('@/assets/img/common/steper/visa/' + (index + 1) + '.svg')
            "
            width="50"
            class="my-5"
          ></v-img>
          <p class="" style="font-size: 120%; font-weight: 500">
            {{ item.name }}
          </p>
          <p class="mb-0" style="font-size: 95%">{{ item.desc }}</p>
        </v-card>
      </v-col>
    </v-row>
    <!-- Visa -->
  </v-container>
</template>

<script>
import StudyWorkflowJSON from "@/assets/data/study-workflow.json";
import VisaflowJSON from "@/assets/data/visa.json";

export default {
  data: () => ({
    btnType: 1,
    StudyWorkflowData: StudyWorkflowJSON,
    VisaflowData: VisaflowJSON,
  }),
};
</script>

<style>
</style>